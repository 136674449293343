#contacts {
  margin-top: 10vh;
}

.contacts_section {
  background-color: #191919;
}

.contacts_section  p, .contacts_section span, .contacts_section a, .contacts_section h1, .contacts_section h2, .contacts_section h3, .contacts_section h4 {
  color: white !important;
}

form input:first-child {
  margin-top: 0;
}

form input, form button {
  height: 40px;
}

form input, form button, form textarea {
  color: white;
  margin: 10px 0px;
  border-radius: 20px;
  border: 1px solid white;
  background-color: #191919;
}

form input, form textarea {
  padding: 10px;
  width: 95%;
}

#nous-rejoindre p {
  width: 85%;
}

form textarea {
  resize: none;
}

form button {
  padding: 5px 20px;
  transition-duration: 0.15s;
}

form button:hover {
  transition-duration: 0.3s;
  background-color: white;
  color: var(--overlay);
  cursor: pointer;
}

.mb-2rem {
  margin-bottom: 2rem;
}

#bottom-copyright-notice {
  font-size: 12px;
  font-weight: lighter;
  padding-top: 80px;
  padding-bottom: 55px;
}

#nous-ecrire {
  max-width: 450px;
}

#socials a img {
  height: 40px;
  width: 40px;
}

#contact-nous-joindre {
  width: 95%;
}

/* MEDIA QUERY FOR LARGE TABLETS AND SMALL DESKTOP */
@media only screen and (max-width:992px){
  
  #contacts {
    padding-left: 30px;
    max-width: unset;
  }

  form input, form textarea, #nous-rejoindre p {
    width: 100%;
  }

  #nous-ecrire, #nous-suivre, #nous-rejoindre {
    margin-top: 50px;
  }

  #contact-content {
    flex-wrap: wrap;
  }

  .mb-2rem {
    margin-bottom: 1rem;
  }

  #bottom-copyright-notice {
    position: unset;
  }
}

/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width:768px){

 }

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px){
  
  #contact-nous-joindre {
    width: 100%;
  }

  #contacts {
    padding-top: 100px;
    margin-top: 0;
    padding-left: 15px !important;
  }
 }
